import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const SignUpWithSelfCreate = () => {
  return (
    <Layout>
      <Seo
        title="Complete Guide How to Register Synkli Account"
        description={`Follow our step-by-step account registration guide to easily set up your Synkli account quickly and efficiently.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`account-registration-guide`} />
    </Layout>
  )
}

export default SignUpWithSelfCreate
